<template>
  <div class="shopping com-overflow-hidden" style="background: #f3f6f8">
    <div style="display: flex; align-items: center; width: 100%">
      <div
        style="
          width: 96%;
          margin-left: 2%;
          margin-right: 2%;
          background: #ffffff;
          min-height: 100vh;
          margin-top: 50px;
          margin-bottom: 100px;
        "
      >
        <div
          style="
            display: flex;
            margin-bottom: 20px;
            margin-left: 20px;
            margin-top: 20px;
            align-items: center;
          "
          v-if="lange == '中文'"
        >
          <div>
            类型：
            <el-select v-model="valueType" placeholder="请选择" @change="changeItem">
              <el-option
                v-for="item in optionType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin-left: 20px">
            品牌：
            <el-select v-model="valueBrand" placeholder="请选择" @change="changeItemp">
              <el-option
                v-for="item in optionBrand"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin-left: 20px; font-weight: 600" @click="clearSelest">
            清空查询
          </div>
        </div>
        <div
          style="
            display: flex;
            margin-bottom: 20px;
            margin-left: 20px;
            margin-top: 20px;
            align-items: center;
          "
          v-else
        >
          <div>
            Type：
            <el-select v-model="valueType" placeholder="请选择" @change="changeItem">
              <el-option
                v-for="item in optionTypeEnglish"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin-left: 20px">
            Brand：
            <el-select v-model="valueBrand" placeholder="请选择" @change="changeItemp">
              <el-option
                v-for="item in optionBrandEnglish"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin-left: 20px; font-weight: 600" @click="clearSelest">
            Clear Search
          </div>
        </div>
        <div style="width: 100%" v-if="lange == '中文'">
          <div v-for="(item, index) in cartList" :key="index" style="">
            <div
              style="
                display: flex;
                align-items: center;
                line-height: 50px;
                font-weight: 500;
                background: #f3f6f8;
                margin: 0px 10px;
                padding: 0 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-top: 20px;
                border: 1px solid #f0f3f5;
              "
            >
              <div style="margin-left: 33px">
                <el-checkbox
                  :value="item.checked"
                  @change="selectItem($event, index)"
                ></el-checkbox>
              </div>
            </div>
            <div
              style="
                margin: 0px 7px;
                padding: 0 7px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column prop="date" label="商品" align="center" min-width="80px"
                  ><template slot-scope="scope">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.imageUrl"
                      :preview-src-list="[item.imageUrl]"
                    >
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="" align="center" min-width="120px">
                  <template slot-scope="scope">
                    <div style="font-size: 12px">
                      <div style="text-align: left">{{ item.cinvCode }}</div>
                      <div style="text-align: left" v-if="item.shoppingMallName">
                        {{ item.brandName + "-" + item.shoppingMallName }}
                      </div>
                      <div style="text-align: left" v-else>
                        {{ item.brandName }}
                      </div>

                      <div style="text-align: left">
                        {{
                          item.sellAttributeId == 1
                            ? "现货销售"
                            : item.sellAttributeId == 2
                            ? "生产预售"
                            : item.sellAttributeId == 3
                            ? "在途预售"
                            : item.sellAttributeId == 7
                            ? "客户翻单"
                            : "新品预售"
                        }}
                      </div>
                    </div>
                    <div style="font-size: 12px">
                      <div style="text-align: left">添加人：{{ item.creater }}</div>
                      <div style="text-align: left">添加时间：{{ item.createTime }}</div>
                    </div>
                    <div style="font-size: 12px" v-if="item.sellAttributeId != 1">
                      <div style="text-align: left">
                        预计发货时间：{{ item.deliveryDate }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="颜色配比"
                  align="center"
                  min-width="240px"
                >
                  <template slot-scope="scope">
                    <div style="position: relative">
                      <div ref="imgListp" style="display: flex; flex-wrap: wrap">
                        <div
                          v-for="(items, indexw) in item.shoppingCartDeatailListCopy"
                          v-if="items.colorQuantity"
                          :key="indexw"
                          style="
                            display: flex;
                            aligin-item: center;
                            flex-direction: column;
                            margin-right: 2%;
                            margin-bottom: 20px;
                            width: 22%;
                          "
                        >
                          <el-image
                            style="
                              width: 100%;
                              height: 100%;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="items.imageUrls"
                            :preview-src-list="items.imageUrlLists"
                          >
                          </el-image>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-if="!item.lpButtonShow"
                          >
                            <div class="counters">
                              <div style="font-size: 21px; color: #c1c1c1">-</div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div style="font-size: 21px; color: #c1c1c1">+</div>
                            </div>
                          </div>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else
                          >
                            <div class="counters">
                              <div
                                style="font-size: 21px; color: black"
                                @click="decrementItem(items, indexw, index)"
                              >
                                -
                              </div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div
                                style="font-size: 21px; color: black"
                                @click="incrementItem(items, indexw, index)"
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 20px 0;
                          position: absolute;
                          bottom: -20px;
                          left: 0%;
                        "
                        v-if="muchShow"
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-down"></i>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 20px 0;
                        "
                        v-else
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-up"></i>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="单价/psc"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    <span v-if="organizationNames == '迪拜'">
                      {{ item.picePrice ? item.picePrice.toFixed(2) + "/DHS" : "" }}</span
                    >
                    <span v-else>
                      {{ item.picePrice ? item.picePrice.toFixed(2) + "/RMB" : "" }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="体积/m³"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    {{ item.volume ? item.volume.toFixed(2) : "" }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="address"
                  label="购买数量/PCS"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope"> {{ item.countSum }} </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="购买箱量/CTN"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div class="counter">
                        <div
                          style="font-size: 21px; color: black"
                          @click="decrementPacking(item, index)"
                        >
                          -
                        </div>

                        <div>
                          <input
                            type="number"
                            :maxlength="11"
                            v-model="item.boxNumber"
                            class="input"
                            min="1"
                            @change="buyCountChange(item, index)"
                            placeholder-style="color:#adc9d8;"
                            style="
                              width: 37px;
                              border: none;
                              outline: none;
                              height: 60%;
                              color: #333;
                              font-size: 16px;
                              margin-left: 25px;
                            "
                          />
                        </div>
                        <div
                          style="font-size: 21px; color: black"
                          @click="incrementPacking(item, index)"
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="organizationName == '迪拜' ? '总金额/DHS' : '总金额/RMB'"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    {{ item.totalPrice.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  label="操作"
                  min-width="80px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="deleteCartItem(item)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: start;
              justify-content: space-between;
              padding: 40px 15px;
              background: #f2f2f2;
              margin: 40px 20px;
            "
            v-if="cartList.length != 0"
          >
            <div style="display: flex; margin-left: 40px; align-items: center">
              <div>
                <el-checkbox v-model="checked" @change="allSelect">全选</el-checkbox>
              </div>
              <div>
                <!-- <el-button style="margin-left: 20px" size="mini">删除</el-button> -->
              </div>
            </div>
            <div style="display: flex; margin-right: 4%; width: 50%">
              <div style="width: 50%">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div>开票名称</div>
                  <div style="font-size: 14px">{{ piaohaoObject.customername }}</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div>税号</div>
                  <div style="font-size: 14px">{{ piaohaoObject.ccusRegCode }}</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div style="color: #ec1c24">
                    *如果开票公司名称或者税号错误请联系您的业务员，或者在“我的”里面进行修改，以免照成开票错误。
                  </div>
                </div>
              </div>

              <div style="width: 50%; margin-left: 40px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>是否开具发票</div>
                  <el-checkbox v-model="checked1" @change="checkedChange"></el-checkbox>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>总数量</div>
                  <div style="font-size: 14px">{{ buyCounterPcsSum }}pcs</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>总体积</div>
                  <div style="font-size: 14px">
                    {{ buyCounterVolumeSum.toFixed(2) }}m³
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>总箱数</div>
                  <div style="font-size: 14px">{{ buyCounterCtnSum }}CTN</div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">不含税金额</div>
                  <div style="font-size: 20px; color: #ec1c24">
                    {{ buyCounterMoneySum.toFixed(2)
                    }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">税率</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ taxRate }}%
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">税额</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ tax }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">含税金额</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ totalTaxMoney }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>
                    0{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                </div>
                <div
                  style="
                    width: 100%;
                    justify-content: center;
                    margin-top: 20px;
                    margin-bottom: 20px;
                  "
                  @click="addDcOrdercenterOrderClick"
                >
                  <div
                    class="counters"
                    style="
                      font-weight: 500;
                      background: black;
                      color: white;
                      text-align: center;
                      justify-content: center;
                      line-height: 28px;
                    "
                  >
                    <div>确认订购</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" v-else>
          <div v-for="(item, index) in cartList" :key="index" style="">
            <div
              style="
                display: flex;
                align-items: center;
                line-height: 50px;
                font-weight: 500;
                background: #f3f6f8;
                margin: 0px 10px;
                padding: 0 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                margin-top: 20px;
                border: 1px solid #f0f3f5;
              "
            >
              <div style="margin-left: 33px">
                <el-checkbox
                  :value="item.checked"
                  @change="selectItem($event, index)"
                ></el-checkbox>
              </div>
            </div>
            <div
              style="
                margin: 0px 7px;
                padding: 0 7px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column
                  prop="date"
                  label="Commodity"
                  align="center"
                  min-width="110px"
                  ><template slot-scope="scope">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.imageUrl"
                      :preview-src-list="[item.imageUrl]"
                    >
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="" align="center" min-width="180px">
                  <template slot-scope="scope">
                    <div style="font-size: 12px">
                      <div style="text-align: left">{{ item.cinvCode }}</div>
                      <div style="text-align: left" v-if="item.shoppingMallName">
                        {{ item.brandName + "-" + item.shoppingMallName }}
                      </div>
                      <div style="text-align: left" v-else>
                        {{ item.brandName }}
                      </div>

                      <div style="text-align: left">
                        {{
                          item.sellAttributeId == 1
                            ? "In Stock"
                            : item.sellAttributeId == 2
                            ? "Pre-Sale In Production"
                            : item.sellAttributeId == 3
                            ? "Pre-Sale In Transit"
                            : "New Arrival"
                        }}
                      </div>
                    </div>
                    <div style="font-size: 12px">
                      <div style="text-align: left">Add people：{{ item.creater }}</div>
                      <div style="text-align: left">Add Time：{{ item.createTime }}</div>
                    </div>
                    <div style="font-size: 12px" v-if="item.sellAttributeId != 1">
                      <div style="text-align: left">
                        Estimated delivery time：{{ item.deliveryDate }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="ColorRatio"
                  align="center"
                  min-width="240px"
                >
                  <template slot-scope="scope">
                    <div style="position: relative">
                      <div ref="imgListp" style="display: flex; flex-wrap: wrap">
                        <div
                          v-for="(items, indexw) in item.shoppingCartDeatailListCopy"
                          v-if="items.colorQuantity"
                          :key="indexw"
                          style="
                            display: flex;
                            aligin-item: center;
                            flex-direction: column;
                            margin-right: 2%;
                            margin-bottom: 20px;
                            width: 22%;
                          "
                        >
                          <el-image
                            style="
                              width: 100%;
                              height: 100%;
                              margin-right: 10px;
                              margin-bottom: 10px;
                            "
                            :src="items.imageUrls"
                            :preview-src-list="items.imageUrlLists"
                          >
                          </el-image>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-if="!item.lpButtonShow"
                          >
                            <div class="counters">
                              <div style="font-size: 21px; color: #c1c1c1">-</div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div style="font-size: 21px; color: #c1c1c1">+</div>
                            </div>
                          </div>
                          <div
                            style="
                              width: 100%;
                              justify-content: center;
                              align-items: center;
                            "
                            v-else
                          >
                            <div class="counters">
                              <div
                                style="font-size: 21px; color: black"
                                @click="decrementItem(items, indexw, index)"
                              >
                                -
                              </div>
                              <div style="font-weight: 500">
                                {{ items.colorQuantity }}
                              </div>
                              <div
                                style="font-size: 21px; color: black"
                                @click="incrementItem(items, indexw, index)"
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 20px 0;
                          position: absolute;
                          bottom: -20px;
                          left: 0%;
                        "
                        v-if="muchShow"
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-down"></i>
                      </div>
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          align-items: center;
                          justify-content: center;
                          font-size: 18px;
                          margin: 20px 0;
                        "
                        v-else
                        @click="muchShowClick(index)"
                      >
                        <i class="el-icon-arrow-up"></i>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="UnitPrice/PCS"
                  align="center"
                  min-width="120px"
                >
                  <template slot-scope="scope">
                    <span v-if="organizationNames == '迪拜'">
                      {{ item.picePrice ? item.picePrice.toFixed(2) + "/DHS" : "" }}</span
                    >
                    <span v-else>
                      {{ item.picePrice ? item.picePrice.toFixed(2) + "/RMB" : "" }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="CBM/m³"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope">
                    {{ item.volume ? item.volume.toFixed(2) : "" }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="address"
                  label="QTY/PCS"
                  align="center"
                  min-width="80px"
                >
                  <template slot-scope="scope"> {{ item.countSum }}</template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="CTN"
                  align="center"
                  min-width="150px"
                >
                  <template slot-scope="scope">
                    <div
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <div class="counter">
                        <div
                          style="font-size: 21px; color: black"
                          @click="decrementPacking(item, index)"
                        >
                          -
                        </div>
                        <div>
                          <input
                            type="number"
                            :maxlength="11"
                            v-model="item.boxNumber"
                            class="input"
                            min="1"
                            @change="buyCountChange(item, index)"
                            placeholder-style="color:#adc9d8;"
                            style="
                              width: 37px;
                              border: none;
                              outline: none;
                              height: 60%;
                              color: #333;
                              font-size: 16px;
                              margin-left: 25px;
                            "
                          />
                        </div>
                        <div
                          style="font-size: 21px; color: black"
                          @click="incrementPacking(item, index)"
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="
                    organizationName == '迪拜' ? 'TotalAmount/DHS' : 'TotalAmount/RMB'
                  "
                  align="center"
                  min-width="140px"
                >
                  <template slot-scope="scope">
                    {{ item.totalPrice.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  label="Setting"
                  width="80px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="deleteCartItem(item)"
                      >delete</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: start;
              justify-content: space-between;
              padding: 40px 15px;
              background: #f2f2f2;
              margin: 40px 20px;
            "
            v-if="cartList.length != 0"
          >
            <div
              style="display: flex; margin-left: 40px; align-items: center"
              v-if="lange == '中文'"
            >
              <div>
                <el-checkbox v-model="checked" @change="allSelect">全选</el-checkbox>
              </div>
              <div>
                <!-- <el-button style="margin-left: 20px" size="mini">删除</el-button> -->
              </div>
            </div>
            <div style="display: flex; margin-left: 40px; align-items: center" v-else>
              <div>
                <el-checkbox v-model="checked" @change="allSelect">
                  Select All</el-checkbox
                >
              </div>
              <div>
                <!-- <el-button style="margin-left: 20px" size="mini">删除</el-button> -->
              </div>
            </div>

            <div
              style="display: flex; margin-right: 4%; width: 28%"
              v-if="lange == '中文' && showNumber != 0"
            >
              <div style="width: 50%">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div>开票名称</div>
                  <div style="font-size: 14px">{{ piaohaoObject.customername }}</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div>税号</div>
                  <div style="font-size: 14px">{{ piaohaoObject.ccusRegCode }}</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div style="color: #ec1c24">
                    *如果开票公司名称或者税号错误请联系您的业务员，或者在“我的”里面进行修改，以免照成开票错误。
                  </div>
                </div>
              </div>

              <div style="width: 50%; margin-left: 40px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>是否开具发票</div>
                  <el-checkbox v-model="checked1" @change="checkedChange"></el-checkbox>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>总数量</div>
                  <div style="font-size: 14px">{{ buyCounterPcsSum }}pcs</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>总体积</div>
                  <div style="font-size: 14px">
                    {{ buyCounterVolumeSum.toFixed(2) }}m³
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>总箱数</div>
                  <div style="font-size: 14px">{{ buyCounterCtnSum }}CTN</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">不含税金额</div>
                  <div style="font-size: 20px; color: #ec1c24">
                    {{ buyCounterMoneySum.toFixed(2)
                    }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">税率</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ taxRate }}%
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">税额</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ tax }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">含税金额</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ totalTaxMoney }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>
                    0{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                </div>
                <div
                  style="
                    width: 100%;
                    justify-content: center;
                    margin-top: 20px;
                    margin-bottom: 20px;
                  "
                  @click="addDcOrdercenterOrderClick"
                >
                  <div
                    class="counters"
                    style="
                      font-weight: 500;
                      background: black;
                      color: white;
                      text-align: center;
                      justify-content: center;
                      line-height: 28px;
                    "
                  >
                    <div>确认订购</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="display: flex; margin-right: 4%; width: 60%"
              v-if="lange == 'English' && showNumber != 0"
            >
              <div style="width: 50%">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div>Invoice Name</div>
                  <div style="font-size: 14px">{{ piaohaoObject.customername }}</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div>duty paragraph</div>
                  <div style="font-size: 14px">{{ piaohaoObject.ccusRegCode }}</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                  v-if="checked1 == true"
                >
                  <div style="color: #ec1c24">
                    *If the name or tax number of the invoicing company is incorrect,
                    please contact your salesperson or make modifications in "My" to avoid
                    invoicing errors.
                  </div>
                </div>
              </div>

              <div style="width: 50%; margin-left: 40px">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>Whether to issue an invoice</div>
                  <el-checkbox v-model="checked1" @change="checkedChange"></el-checkbox>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>Total quantity</div>
                  <div style="font-size: 14px">{{ buyCounterPcsSum }}pcs</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>Total Volume</div>
                  <div style="font-size: 14px">
                    {{ buyCounterVolumeSum.toFixed(2) }}m³
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div>Total number of boxes</div>
                  <div style="font-size: 14px">{{ buyCounterCtnSum }}CTN</div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">Excluding tax amount</div>
                  <div style="font-size: 20px; color: #ec1c24">
                    {{ buyCounterMoneySum.toFixed(2)
                    }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">tax rate</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ taxRate }}%
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">Tax amount</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ tax }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>0</div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    font-weight: 600;
                    line-height: 28px;
                  "
                >
                  <div style="">Tax inclusive amount</div>
                  <div style="font-size: 20px; color: #ec1c24" v-if="checked1 == true">
                    {{ totalTaxMoney }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                  <div style="font-size: 20px; color: #ec1c24" v-else>
                    0{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
                  </div>
                </div>
                <div
                  style="
                    width: 100%;
                    justify-content: center;
                    margin-top: 20px;
                    margin-bottom: 20px;
                  "
                  @click="addDcOrdercenterOrderClick"
                >
                  <div
                    class="counters"
                    style="
                      font-weight: 500;
                      background: black;
                      color: white;
                      text-align: center;
                      justify-content: center;
                      line-height: 28px;
                    "
                  >
                    <div>Confirm order</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="payment"
      :visible.sync="dialogVisible"
      width="50%"
      v-if="lange == '中文'"
    >
      <div style="display: flex" v-if="checked1 == true">
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            line-height: 28px;
            margin-right: 40px;
          "
        >
          <div style="">含税金额：</div>
          <div style="font-size: 15px; color: #ec1c24" v-if="checked1 == true">
            {{ totalTaxMoney }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
          </div>
        </div>
      </div>

      <div style="display: flex" v-else>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            line-height: 28px;
            margin-right: 40px;
          "
        >
          <div style="">不含税金额：</div>
          <div style="font-size: 20px; color: #ec1c24">
            {{ buyCounterMoneySum.toFixed(2)
            }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">
            {{
              listItem.paymentCodeWeChatName
                ? listItem.paymentCodeWeChatName
                : "暂无设置"
            }}：
          </div>
          <div>
            <el-image
              v-if="listItem.paymentCode_WeChat"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_WeChat"
              :preview-src-list="[listItem.paymentCode_WeChat]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeAlipayName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Alipay"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Alipay"
              :preview-src-list="[listItem.paymentCode_Alipay]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
        <div style="width: 30%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeBankName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Bank"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Bank"
              :preview-src-list="[listItem.paymentCode_Bank]"
            >
            </el-image>
            <div v-else>暂无设置</div>
          </div>
        </div>
      </div>
      <div v-if="listItem.isPCShowCompany" style="margin-top: 50px">
        <div>{{ listItem.paymentCodeCompanyName }}:</div>

        <div
          v-html="listItem.paymentCode_Company"
          v-if="listItem.paymentCode_Company"
        ></div>
        <div v-else>暂无设置</div>
      </div>
      <div style="display: flex; justify-content: center; width: 100%">
        <el-button type="danger" @click="addDcOrdercenterOrderClick1">稍后支付</el-button>
        <el-button type="success" @click="dialogVisibles = true">上传凭证</el-button>
      </div>
    </el-dialog>
    <el-dialog title="payment" :visible.sync="dialogVisible" width="40%" v-else>
      <div style="display: flex" v-if="checked1 == true">
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            line-height: 28px;
            margin-right: 40px;
          "
        >
          <div style="">Tax inclusive amount：</div>
          <div style="font-size: 15px; color: #ec1c24" v-if="checked1 == true">
            {{ totalTaxMoney }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
          </div>
        </div>
      </div>

      <div style="display: flex" v-else>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            line-height: 28px;
            margin-right: 40px;
          "
        >
          <div style="">Excluding tax amount：</div>
          <div style="font-size: 20px; color: #ec1c24">
            {{ buyCounterMoneySum.toFixed(2)
            }}{{ organizationName == "迪拜" ? "DHS" : "/RMB" }}
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">
            {{
              listItem.paymentCodeWeChatName
                ? listItem.paymentCodeWeChatName
                : "暂无设置"
            }}：
          </div>
          <div>
            <el-image
              v-if="listItem.paymentCode_WeChat"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_WeChat"
              :preview-src-list="[listItem.paymentCode_WeChat]"
            >
            </el-image>
            <div v-else>No settings available at the moment</div>
          </div>
        </div>
        <div style="width: 30%; margin-right: 2%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeAlipayName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Alipay"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Alipay"
              :preview-src-list="[listItem.paymentCode_Alipay]"
            >
            </el-image>
            <div v-else>No settings available at the moment</div>
          </div>
        </div>
        <div style="width: 30%">
          <div style="margin-bottom: 20px">{{ listItem.paymentCodeBankName }}：</div>
          <div>
            <el-image
              v-if="listItem.paymentCode_Bank"
              style="width: 100%; height: 100%"
              :src="listItem.paymentCode_Bank"
              :preview-src-list="[listItem.paymentCode_Bank]"
            >
            </el-image>
            <div v-else>No settings available at the moment</div>
          </div>
        </div>
      </div>
      <div v-if="listItem.isPCShowCompany" style="margin-top: 50px">
        <div>{{ listItem.paymentCodeCompanyName }}:</div>

        <div
          v-html="listItem.paymentCode_Company"
          v-if="listItem.paymentCode_Company"
        ></div>
        <div v-else>No settings available at the moment</div>
      </div>
      <div style="display: flex; justify-content: center; width: 100%">
        <el-button type="danger" @click="addDcOrdercenterOrderClick1"
          >Pay Later</el-button
        >
        <el-button type="success" @click="dialogVisibles = true"
          >Upload credentials</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="凭证上传"
      :visible.sync="dialogVisibles"
      width="40%"
      append-to-body
      v-if="lange == '中文'"
    >
      <div style="display: flex; justify-content: center; width: 100%">
        <el-upload
          action=""
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :http-request="httprequest"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisiblek">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px">
        <el-button type="success" @click="addDcOrdercenterOrderClick2"
          >上传凭证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="Upload credentials"
      :visible.sync="dialogVisibles"
      width="40%"
      append-to-body
      v-else
    >
      <div style="display: flex; justify-content: center; width: 100%">
        <el-upload
          action=""
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :http-request="httprequest"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisiblek">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px">
        <el-button type="success" @click="addDcOrdercenterOrderClick2"
          >Upload credentials</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDcShoppingmallShoppingcart,
  deleteDcShoppingmallShoppingcart,
  editDcShoppingmallShoppingcart,
  addDcOrdercenterOrder,
  getCustomerPaymentCode,
  addCustomerOrderPaymentImage,
  dcCustomercenterPaymentcodeQueryDefault,
  getCustomerInfo,
} from "@/api/cart/index.js";
import { mapGetters } from "vuex";
import { getDcBasearchiveCommodityPackingratio } from "@/api/good/index.js";
import ShopItem from "./Shoping/ShopItem.vue";
import shopOrder from "./shopOrder/index.vue";
import { encryptionKey } from "@/config";
import CryptoJS from "crypto-js";
export default {
  name: "ShoppingVue",
  data() {
    return {
      listItem: {
        paymentCodeWeChatName: "",
        paymentCodeAlipayName: "",
        paymentCodeBankName: "",
        paymentCodeCompanyName: "",
        isPCShowWeChat: "",
        isPCShowAlipay: "",
        isPCShowBank: "",
        isPCShowCompany: "",
        isAPPShowWeChat: "",
        isAPPShowAlipay: "",
        isAPPShowBank: "",
        isAPPShowCompany: "",
      },
      activeName: "first",
      dialogVisible: false,
      dialogVisibles: false,
      dialogVisiblek: false,
      foundDifferent: false,

      valueType: "",
      valueBrand: "",
      activeName1: "first",
      buyCounterPcsSum: 0,
      buyCounterVolumeSum: 0,
      buyCounterCtnSum: 0,
      buyCounterMoneySum: 0,
      muchShow: true,
      totalTaxMoney: 0,
      tax: 0,
      taxRate: 0,
      isNeedInvoice: 0,
      checked1: false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      CommodityClass: [],
      isHome: false,
      isActive: false,
      isAllSelected: false,
      allShop: "0.00",
      dialogTableVisible: false,
      dialogVisiblep: false,
      imageHight: 0,
      dialogImageUrl: "",
      checkep: false,
      lpButtonShow: false,
      checked: false,
      cartList: [],
      yushouCartList: [],
      xianhuoCartList: [],
      priceCounter: 0,
      buyCountSum: 0,
      buyCountSumMoney: 0,
      chrisbellaAll: [],
      SUSENAll: [],
      BAGCOAll: [],
      chrisbellaAllys: [],
      SUSENAllys: [],
      BAGCOAllys: [],
      chrisbellaAllxh: [],
      SUSENAllxh: [],
      BAGCOAllxh: [],
      optionType: [
        { value: "0", label: "全部" },
        { value: "2", label: "预售" },
        { value: "1", label: "现货" },
      ],
      optionBrand: [
        { value: "全部", label: "全部" },
        { value: "CHRISBELLA", label: "CHRISBELLA" },
        { value: "SUSEN零售", label: "SUSEN" },
        { value: "Bagco", label: "BAGCO" },
      ],
      optionTypeEnglish: [
        { value: "0", label: "All" },
        { value: "2", label: "Pre-Sale" },
        { value: "1", label: "In Stock" },
      ],
      organizationNames: localStorage.getItem("organizationNames"),
      optionBrandEnglish: [
        { value: "全部", label: "All" },
        { value: "CHRISBELLA", label: "CHRISBELLA" },
        { value: "SUSEN零售", label: "SUSEN" },
        { value: "Bagco", label: "BAGCO" },
      ],
      piaohaoObject: {},
      orderId: "",
      showNumber: 0,
      discount: 1,
      addPrice: 0,
      brandName: this.$route.query.brandName,
    };
  },
  components: {
    ShopItem,
    shopOrder,
  },
  computed: {
    ...mapGetters({
      lange: "user/lange",
      organizationName: "user/organizationName",
      priceChangeList: "user/priceChangeList",
    }),
  },
  watch: {
    startTime(newVal, oldVal) {
      this.outTime(this.startTime, this.dayNum);
    },
  },
  mounted() {
    getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 1000 }).then((res) => {
      if (this.priceChangeList.length != 0) {
        if (this.brandName == "CHRISBELLA") {
          let discountLength = this.priceChangeList.filter((item) => {
            return item.brandName == "CHRISBELLA";
          });
          if (discountLength.length != 0) {
            if (discountLength[0].discount) {
              this.discount = discountLength[0].discount / 100;
            } else {
              this.discount = 1;
            }
          }
          let addPriceLength = this.priceChangeList.filter((item) => {
            return item.brandName == "CHRISBELLA";
          });
          if (addPriceLength.length != 0) {
            if (addPriceLength[0].priceadjustment) {
              this.addPrice = addPriceLength[0].priceadjustment;
            } else {
              this.addPrice = 0;
            }
          }
        } else if (this.brandName == "BAGCO") {
          let discountLength = this.priceChangeList.filter((item) => {
            return item.brandName == "BAGCO";
          });
          if (discountLength.length != 0) {
            if (discountLength[0].discount) {
              this.discount = discountLength[0].discount / 100;
            } else {
              this.discount = 1;
            }
          }
          let addPriceLength = this.priceChangeList.filter((item) => {
            return item.brandName == "BAGCO";
          });
          if (addPriceLength.length != 0) {
            if (addPriceLength[0].priceadjustment) {
              this.addPrice = addPriceLength[0].priceadjustment;
            } else {
              this.addPrice = 0;
            }
          }
        } else {
          let discountLength = this.priceChangeList.filter((item) => {
            return item.brandName == "SUSEN零售";
          });
          if (discountLength.length != 0) {
            if (discountLength[0].discount) {
              this.discount = discountLength[0].discount / 100;
            } else {
              this.discount = 1;
            }
          }
          let addPriceLength = this.priceChangeList.filter((item) => {
            return item.brandName == "SUSEN零售";
          });
          if (addPriceLength.length != 0) {
            if (addPriceLength[0].priceadjustment) {
              this.addPrice = addPriceLength[0].priceadjustment;
            } else {
              this.addPrice = 0;
            }
          }
        }
      }
      this.cartList = res.data;

      this.cartList.forEach((item) => {
        item.checked = false;
        item.commodityPriceList.forEach((item) => {
          item.priceAfter = (item.priceAfter * 1 + this.addPrice * 1) * this.discount;
        });
        if (item.sellAttributeList) {
          let haveDictnameLP = item.sellAttributeList.filter((itemp) => {
            return itemp.dictname == "零批";
          });
          if (haveDictnameLP.length != 0) {
            item.lpButtonShow = true;
          } else {
            if (item.packingQuantity > item.availableQuantity) {
              item.lpButtonShow = true;
            } else {
              item.lpButtonShow = false;
            }
          }
          console.log(item.cinvCode + "-" + item.lpButtonShow);
        }
        item.colorSmallList = [];
        item.packingRatioList.forEach((iteml) => {
          item.fpeiBiCopy = item.fpeiBi * 1;
          item.colorSmallList.push(Math.floor(iteml.iquantity / iteml.fpeiBi));
        });
        item.boxMaxBumber = Math.min(...item.colorSmallList);
        item.countSum = 0;
        item.colorQuantity = 0;
        if (item.boxNumber == 0) {
          item.shoppingCartDeatailList.forEach((iteml) => {
            item.countSum = item.countSum * 1 + iteml.colorQuantity * 1;
          });
        } else {
          if (item.availableQuantity == item.quantity) {
            item.countSum = item.availableQuantity;
          } else {
            item.countSum = item.boxNumber * item.packingQuantity;
          }
        }
        // item.packingRatioList.forEach((items) => {
        //   item.colorQuantity = items.fpeiBi * item.boxNumber + item.colorQuantity * 1;
        // });
        // item.countSum = item.colorQuantity * 1 + item.countSum * 1;

        if (item.commodityPriceList && item.commodityPriceList.length != 0) {
          item.picePrice =
            item.commodityPriceList[
              this.findMaxIndex(item.commodityPriceList, item.countSum)
            ].priceAfter;
        } else {
          item.picePrice = 0;
        }

        item.totalPrice = item.picePrice * item.countSum;
        item.volume = (item.countSum / item.packingQuantity / 1000000) * item.ivolume;
        item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
        console.log(item.imageUrl);
        item.shoppingCartDeatailList.forEach((items) => {
          items.imageUrls =
            this.$locationUrl +
            "file/abyFile/commodityFile/commodityColorImage/" +
            items.fileName;
          items.imageUrlLists = [item.imageUrls];
          // console.log(items.imageUrls);
        });
        item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
      });
      console.log(this.cartList);
    });
    getCustomerInfo({}).then((res) => {
      this.piaohaoObject = res.data;
    });
    getCustomerPaymentCode({}).then((res) => {
      if (res.code == 200) {
        if (JSON.stringify(res.data) != "{}") {
          this.listItem = res.data;

          if (this.organizationNames != "迪拜") {
            if (this.listItem.isPCShowWeChat == 1) {
              this.listItem.isPCShowWeChat = true;
            } else {
              this.listItem.isPCShowWeChat = false;
            }
            if (this.listItem.isPCShowAlipay == 1) {
              this.listItem.isPCShowAlipay = true;
            } else {
              this.listItem.isPCShowAlipay = false;
            }
            if (this.listItem.isPCShowBank == 1) {
              this.listItem.isPCShowBank = true;
            } else {
              this.listItem.isPCShowBank = false;
            }
            if (this.listItem.isAPPShowWeChat == 1) {
              this.listItem.isAPPShowWeChat = true;
            } else {
              this.listItem.isAPPShowWeChat = false;
            }
            if (this.listItem.isAPPShowAlipay == 1) {
              this.listItem.isAPPShowAlipay = true;
            } else {
              this.listItem.isAPPShowAlipay = false;
            }
            if (this.listItem.isAPPShowBank == 1) {
              this.listItem.isAPPShowBank = true;
            } else {
              this.listItem.isAPPShowBank = false;
            }
            if (this.listItem.paymentCode_Alipay) {
              this.listItem.paymentCode_Alipay = this.codeChange(
                this.listItem.paymentCode_Alipay
              );
            } else {
              this.listItem.paymentCode_Alipay = "";
            }
            if (this.listItem.paymentCode_Bank) {
              this.listItem.paymentCode_Bank = this.codeChange(
                this.listItem.paymentCode_Bank
              );
            } else {
              this.listItem.paymentCode_Bank = "";
            }
            if (this.listItem.paymentCode_WeChat) {
              this.listItem.paymentCode_WeChat = this.codeChange(
                this.listItem.paymentCode_WeChat
              );
            } else {
              this.listItem.paymentCode_WeChat = "";
            }
          } else {
            if (this.listItem.isPCShowWeChat1 == 1) {
              this.listItem.isPCShowWeChat1 = true;
            } else {
              this.listItem.isPCShowWeChat1 = false;
            }
            if (this.listItem.isPCShowAlipay1 == 1) {
              this.listItem.isPCShowAlipay1 = true;
            } else {
              this.listItem.isPCShowAlipay1 = false;
            }
            if (this.listItem.isPCShowBank1 == 1) {
              this.listItem.isPCShowBank1 = true;
            } else {
              this.listItem.isPCShowBank1 = false;
            }
            if (this.listItem.isAPPShowWeChat1 == 1) {
              this.listItem.isAPPShowWeChat1 = true;
            } else {
              this.listItem.isAPPShowWeChat1 = false;
            }
            if (this.listItem.isAPPShowAlipay1 == 1) {
              this.listItem.isAPPShowAlipay1 = true;
            } else {
              this.listItem.isAPPShowAlipay1 = false;
            }
            if (this.listItem.isAPPShowBank1 == 1) {
              this.listItem.isAPPShowBank1 = true;
            } else {
              this.listItem.isAPPShowBank1 = false;
            }
            if (this.listItem.paymentCode_Alipay1) {
              this.listItem.paymentCode_Alipay1 = this.codeChange(
                this.listItem.paymentCode_Alipay1
              );
            } else {
              this.listItem.paymentCode_Alipay1 = "";
            }
            if (this.listItem.paymentCode_Bank1) {
              this.listItem.paymentCode_Bank1 = this.codeChange(
                this.listItem.paymentCode_Bank1
              );
            } else {
              this.listItem.paymentCode_Bank1 = "";
            }
            if (this.listItem.paymentCode_WeChat1) {
              this.listItem.paymentCode_WeChat1 = this.codeChange(
                this.listItem.paymentCode_WeChat1
              );
            } else {
              this.listItem.paymentCode_WeChat1 = "";
            }
          }

          console.log(this.listItem);
        } else {
          dcCustomercenterPaymentcodeQueryDefault({ pageSize: 10, pageNum: 1 }).then(
            (resp) => {
              if (res.data.length != 0) {
                this.listItem = resp.data[0];
                console.log("this.listItem", this.listItem);
                console.log(this.organizationNames);
                if (this.organizationNames != "迪拜") {
                  if (this.listItem.isPCShowWeChat == 1) {
                    this.listItem.isPCShowWeChat = true;
                  } else {
                    this.listItem.isPCShowWeChat = false;
                  }
                  if (this.listItem.isPCShowAlipay == 1) {
                    this.listItem.isPCShowAlipay = true;
                  } else {
                    this.listItem.isPCShowAlipay = false;
                  }
                  if (this.listItem.isPCShowBank == 1) {
                    this.listItem.isPCShowBank = true;
                  } else {
                    this.listItem.isPCShowBank = false;
                  }
                  if (this.listItem.isAPPShowWeChat == 1) {
                    this.listItem.isAPPShowWeChat = true;
                  } else {
                    this.listItem.isAPPShowWeChat = false;
                  }
                  if (this.listItem.isAPPShowAlipay == 1) {
                    this.listItem.isAPPShowAlipay = true;
                  } else {
                    this.listItem.isAPPShowAlipay = false;
                  }
                  if (this.listItem.isAPPShowBank == 1) {
                    this.listItem.isAPPShowBank = true;
                  } else {
                    this.listItem.isAPPShowBank = false;
                  }
                  if (this.listItem.paymentCode_Alipay) {
                    this.listItem.paymentCode_Alipay = this.codeChange(
                      this.listItem.paymentCode_Alipay
                    );
                  } else {
                    this.listItem.paymentCode_Alipay = "";
                  }
                  if (this.listItem.paymentCode_Bank) {
                    this.listItem.paymentCode_Bank = this.codeChange(
                      this.listItem.paymentCode_Bank
                    );
                  } else {
                    this.listItem.paymentCode_Bank = "";
                  }
                  if (this.listItem.paymentCode_WeChat) {
                    this.listItem.paymentCode_WeChat = this.codeChange(
                      this.listItem.paymentCode_WeChat
                    );
                  } else {
                    this.listItem.paymentCode_WeChat = "";
                  }
                } else {
                  if (this.listItem.isPCShowWeChat1 == 1) {
                    this.listItem.isPCShowWeChat1 = true;
                  } else {
                    this.listItem.isPCShowWeChat1 = false;
                  }
                  if (this.listItem.isPCShowAlipay1 == 1) {
                    this.listItem.isPCShowAlipay1 = true;
                  } else {
                    this.listItem.isPCShowAlipay1 = false;
                  }
                  if (this.listItem.isPCShowBank1 == 1) {
                    this.listItem.isPCShowBank1 = true;
                  } else {
                    this.listItem.isPCShowBank1 = false;
                  }
                  if (this.listItem.isAPPShowWeChat1 == 1) {
                    this.listItem.isAPPShowWeChat1 = true;
                  } else {
                    this.listItem.isAPPShowWeChat1 = false;
                  }
                  if (this.listItem.isAPPShowAlipay1 == 1) {
                    this.listItem.isAPPShowAlipay1 = true;
                  } else {
                    this.listItem.isAPPShowAlipay1 = false;
                  }
                  if (this.listItem.isAPPShowBank1 == 1) {
                    this.listItem.isAPPShowBank1 = true;
                  } else {
                    this.listItem.isAPPShowBank1 = false;
                  }
                  if (this.listItem.paymentCode_Alipay1) {
                    this.listItem.paymentCode_Alipay1 = this.codeChange(
                      this.listItem.paymentCode_Alipay1
                    );
                  } else {
                    this.listItem.paymentCode_Alipay1 = "";
                  }
                  if (this.listItem.paymentCode_Bank1) {
                    this.listItem.paymentCode_Bank1 = this.codeChange(
                      this.listItem.paymentCode_Bank1
                    );
                  } else {
                    this.listItem.paymentCode_Bank1 = "";
                  }
                  if (this.listItem.paymentCode_WeChat1) {
                    this.listItem.paymentCode_WeChat1 = this.codeChange(
                      this.listItem.paymentCode_WeChat1
                    );
                  } else {
                    this.listItem.paymentCode_WeChat1 = "";
                  }
                }

                console.log("listItem", this.listItem);
                this.$forceUpdate();
              } else {
                this.listItem = {
                  paymentCodeWeChatName: "",
                  paymentCodeAlipayName: "",
                  paymentCodeBankName: "",
                  paymentCodeCompanyName: "",
                  isPCShowWeChat: "",
                  isPCShowAlipay: "",
                  isPCShowBank: "",
                  isPCShowCompany: "",
                  isAPPShowWeChat: "",
                  isAPPShowAlipay: "",
                  isAPPShowBank: "",
                  isAPPShowCompany: "",
                };
              }
            }
          );
        }
      }
    });
    this.$forceUpdate();
  },
  created() {},

  watch: {},
  methods: {
    buyCountChange(item, index) {
      console.log(item);
      if (item.availableQuantity % item.packingQuantity == 0) {
        item = item.availableQuantity / item.packingQuantity;
      } else {
        item.buyCountMax =
          Math.floor(item.availableQuantity / item.packingQuantity) * 1 + 1;
      }
      console.log("最大后购买箱数", item.buyCountMax);
      item.countSum = 0;
      item.buyCountSumMoney = 0;
      console.log(item.boxMaxBumber, item.buyCountMax);
      //item.boxMaxBumber最大可成配比箱数
      //item.buyCountMax 最大可购买箱数
      if (item.boxNumber < item.buyCountMax) {
        if (item.boxNumber > item.boxMaxBumber) {
          item.countSum = 0;
          let fpeiBiPeiBiNumberCount = 0;

          let gapBox = item.boxNumber - item.boxMaxBumber; //输入值与最大装箱配比之间的差值 1
          item.packingRatioList.forEach((itemk) => {
            itemk.fpeiBiPeiBiNumber = 0;

            itemk.fpeiBiCopy = itemk.fpeiBi * 1 * item.boxMaxBumber * 1;

            itemk.fpeiBiPeiBiNumber = itemk.iquantity - itemk.fpeiBiCopy; //计算不成配比箱数各个颜色的剩余库存
            // // console.log(itemk)
            // console.log(itemk.fpeiBiPeiBiNumber);
            // console.log(itemk.fpeiBiCopy);
            console.log("1", itemk.fpeiBiCopy);
            console.log("2", itemk.fpeiBiPeiBiNumber);

            fpeiBiPeiBiNumberCount =
              itemk.fpeiBiPeiBiNumber * 1 + fpeiBiPeiBiNumberCount * 1; //计算不成配比箱数各个颜色的剩余库存之和
          });

          console.log("fpeiBiPeiBiNumberCount", fpeiBiPeiBiNumberCount);
          if (fpeiBiPeiBiNumberCount > item.packingQuantity) {
            //不成配比箱数各个颜色的剩余库存之和大于配比数时
            console.log("//不成配比箱数各个颜色的剩余库存之和大于配比数时");
            let fpeiBiCopySumNumber = 0;
            item.packingRatioList.forEach((itemp) => {
              itemp.fpeiBiPeiBiNumberLv = (
                itemp.fpeiBiPeiBiNumber / fpeiBiPeiBiNumberCount
              ).toFixed(2); //计算不成配比箱数各个颜色的新配比比率
              fpeiBiCopySumNumber =
                Math.round(item.packingQuantity * itemp.fpeiBiPeiBiNumberLv * gapBox) +
                fpeiBiCopySumNumber; //按照不成配比箱数各个颜色的新配比比率重新配比装箱

              itemp.fpeiBiCopy =
                itemp.fpeiBiCopy +
                Math.round(item.packingQuantity * itemp.fpeiBiPeiBiNumberLv * gapBox);
            });
            console.log(
              "//按照不成配比箱数各个颜色的新配比比率重新配比装箱",
              fpeiBiCopySumNumber
            );
            if (fpeiBiCopySumNumber < item.packingQuantity * gapBox) {
              //重新配比装箱数小于系统配比装箱数
              console.log("配比计算数小于配比数");
              const maxElement = item.packingRatioList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < item.packingRatioList.length; i++) {
                if (
                  item.packingRatioList[i].fpeiBiPeiBiNumber ===
                  maxElement.fpeiBiPeiBiNumber
                ) {
                  item.packingRatioList[i].fpeiBiCopy =
                    item.packingRatioList[i].fpeiBiCopy * 1 + 1 * gapBox; // 只给第一个符合条件的元素加1

                  break; // 找到后退出循环
                }
              }
            } else if (fpeiBiCopySumNumber > item.packingQuantity * gapBox) {
              const maxElement = item.packingRatioList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < item.packingRatioList.length; i++) {
                if (
                  item.packingRatioList[i].fpeiBiPeiBiNumber ===
                  maxElement.fpeiBiPeiBiNumber
                ) {
                  item.packingRatioList[i].fpeiBiCopy =
                    item.packingRatioList[i].fpeiBiCopy * 1 - 1 * gapBox; // 只给第一个符合条件的元素加1
                  console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                  break; // 找到后退出循环
                }
              }
            }

            // this.buyCount = this.buyCount + 1;

            item.countSum = item.boxNumber * item.packingQuantity;
            console.log("00000000", item.countSum);
          } else {
            console.log(
              "数量剩余数小于配比装箱数--------------------------------------------------------"
            );

            item.boxNumber = item.buyCountMax;
            item.packingRatioList.forEach((item) => {
              item.fpeiBiCopy = item.iquantity;
              item.buyCountSum = item.availableQuantity;
            });
          }
        } else if (item.availableQuantity == 0) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "库存不足无法修改"
                : "Insufficient inventory cannot be modified",
            type: "error",
            duration: 0,
            offset: 100,
          });
        } else {
          item.packingRatioList.forEach((itemj) => {
            itemj.fpeiBiCopy = itemj.fpeiBi * item.boxNumber;
            item.countSum = item.countSum * 1 + itemj.fpeiBiCopy * 1;
          });
          console.log();
        }
      } else {
        item.boxNumber = item.buyCountMax;
        item.countSum = item.availableQuantity;
        item.packingRatioList.forEach((itemt) => {
          itemt.fpeiBiCopy = itemt.iquantity;
        });
      }
      item.price =
        item.commodityPriceList[
          this.findMaxIndex(item.commodityPriceList, item.countSum)
        ].priceAfter;
      item.totalPrice = item.countSum * item.price;

      item.volume = ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
      item.packingRatioList.forEach((itemPackingRatioList) => {
        item.shoppingCartDeatailList.forEach((itemShoppingCartDeatailList) => {
          if (itemPackingRatioList.id == itemShoppingCartDeatailList.peibiId) {
            itemShoppingCartDeatailList.colorQuantity = itemPackingRatioList.fpeiBiCopy;
          }
        });
      });
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      let shoppingCartDeatailList = [];
      this.cartList[index].shoppingCartDeatailList.forEach((item) => {
        shoppingCartDeatailList.push({
          colorName: item.colorName,
          peibiId: item.peibiId,
          colorQuantity: item.colorQuantity,
          shoppingCartId: item.shoppingCartId,
        });
      });
      editDcShoppingmallShoppingcart({
        shoppingCartId: this.cartList[index].shoppingCartId,
        picePrice: this.cartList[index].picePrice,
        totalPrice: this.cartList[index].totalPrice,
        quantity: this.cartList[index].countSum,
        boxNumber: this.cartList[index].boxNumber,
        volume: this.cartList[index].volume,
        sellAttributeId: this.cartList[index].sellAttributeId,
        cinvCode: this.cartList[index].cinvCode,
        shoppingCartDeatailList: shoppingCartDeatailList,
      }).then((res) => {
        if (res.code == 200) {
        }
      });
      this.$forceUpdate();
    },
    clearSelest() {
      getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 1000 }).then((res) => {
        this.cartList = res.data;

        this.cartList.forEach((item) => {
          item.checked = false;
          if (item.sellAttributeList) {
            let haveDictnameLP = item.sellAttributeList.filter((itemp) => {
              return itemp.dictname == "零批";
            });
            if (haveDictnameLP.length != 0) {
              item.lpButtonShow = true;
            } else {
              if (item.packingQuantity > item.availableQuantity) {
                item.lpButtonShow = true;
              } else {
                item.lpButtonShow = false;
              }
            }
            console.log(item.cinvCode + "-" + item.lpButtonShow);
          }

          item.countSum = 0;
          item.colorQuantity = 0;
          if (item.boxNumber == 0) {
            item.shoppingCartDeatailList.forEach((iteml) => {
              item.countSum = item.countSum * 1 + iteml.colorQuantity * 1;
            });
          } else {
            if (item.availableQuantity == item.quantity) {
              item.countSum = item.availableQuantity;
            } else {
              item.countSum = item.boxNumber * item.packingQuantity;
            }
          }
          // item.packingRatioList.forEach((items) => {
          //   item.colorQuantity = items.fpeiBi * item.boxNumber + item.colorQuantity * 1;
          // });
          // item.countSum = item.colorQuantity * 1 + item.countSum * 1;

          if (item.commodityPriceList && item.commodityPriceList.length != 0) {
            item.picePrice =
              item.commodityPriceList[
                this.findMaxIndex(item.commodityPriceList, item.countSum)
              ].priceAfter;
          } else {
            item.picePrice = 0;
          }

          item.totalPrice = item.picePrice * item.countSum;
          item.volume = ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
          item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
          console.log(item.imageUrl);
          item.shoppingCartDeatailList.forEach((items) => {
            items.imageUrls =
              this.$locationUrl +
              "file/abyFile/commodityFile/commodityColorImage/" +
              items.fileName;
            items.imageUrlLists = [item.imageUrls];
            // console.log(items.imageUrls);
          });
          item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
        });
        console.log(this.cartList);
      });
    },
    codeChange(item) {
      console.log(item);
      var decrypted = CryptoJS.AES.decrypt(item, encryptionKey).toString(
        CryptoJS.enc.Utf8
      );
      console.log(decrypted);
      var bytes = window.atob(decrypted.split(",")[1]);
      //处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: "image/png" });

      // 生成 Blob 对象的 URL 地址
      const imageUrl = URL.createObjectURL(blob);
      console.log(imageUrl);
      return imageUrl;
    },
    httprequest(e) {
      this.formData = new FormData();
      this.formData.append("image", e.file);
      this.formData.append("orderId", this.orderId);
      addCustomerOrderPaymentImage(this.formData).then((res) => {
        this.$message({
          message: "图片上传成功",
          type: "success",
        });
      });
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisiblek = true;
    },
    checkedChange(event) {
      if (event == true) {
        this.isNeedInvoice = 1;
        this.taxRate = this.piaohaoObject.taxRate;
        this.totalTaxMoney = (
          this.buyCounterMoneySum *
          ((100 + this.taxRate) / 100)
        ).toFixed(2);
        this.tax = (this.buyCounterMoneySum * (this.taxRate / 100)).toFixed(2);
      } else {
        this.isNeedInvoice = 0;
        this.taxRate = 0;
        this.tax = 0;
        this.totalTaxMoney = this.buyCounterMoneySum;
      }
    },
    checkDifferentValueWithForEach(array) {
      const firstValue = array[0].sellAttributeId; // 获取第一个元素的 value
      this.foundDifferent = false;

      array.forEach((item) => {
        if ((item.sellAttributeId !== firstValue) & !this.foundDifferent) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "不同类型购物车商品不能同时下单，请分开购买"
                : "Different types of shopping cart items cannot be ordered simultaneously, please purchase separately",
            type: "error",
            duration: 0,
            offset: 100,
          });
          this.foundDifferent = true;
        }
      });
    },
    changeItem(event) {
      console.log(event);
      if (this.valueType == "0") {
        getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 1000 }).then((res) => {
          this.cartList = res.data;

          this.cartList.forEach((item) => {
            item.checked = false;
            if (item.sellAttributeList) {
              let haveDictnameLP = item.sellAttributeList.filter((itemp) => {
                return itemp.dictname == "零批";
              });
              if (haveDictnameLP.length != 0) {
                item.lpButtonShow = true;
              } else {
                if (item.packingQuantity > item.availableQuantity) {
                  item.lpButtonShow = true;
                } else {
                  item.lpButtonShow = false;
                }
              }
              console.log(item.cinvCode + "-" + item.lpButtonShow);
            }

            item.countSum = 0;
            item.colorQuantity = 0;
            if (item.boxNumber == 0) {
              item.shoppingCartDeatailList.forEach((iteml) => {
                item.countSum = item.countSum * 1 + iteml.colorQuantity * 1;
              });
            } else {
              if (item.availableQuantity == item.quantity) {
                item.countSum = item.availableQuantity;
              } else {
                item.countSum = item.boxNumber * item.packingQuantity;
              }
            }
            // item.packingRatioList.forEach((items) => {
            //   item.colorQuantity = items.fpeiBi * item.boxNumber + item.colorQuantity * 1;
            // });
            // item.countSum = item.colorQuantity * 1 + item.countSum * 1;

            if (item.commodityPriceList && item.commodityPriceList.length != 0) {
              item.picePrice =
                item.commodityPriceList[
                  this.findMaxIndex(item.commodityPriceList, item.countSum)
                ].priceAfter;
            } else {
              item.picePrice = 0;
            }

            item.totalPrice = item.picePrice * item.countSum;
            item.volume =
              ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
            item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
            console.log(item.imageUrl);
            item.shoppingCartDeatailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              // console.log(items.imageUrls);
            });
            item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
          });
          console.log(this.cartList);
        });
        this.$forceUpdate();
      } else {
        this.valueType = event;
        getDcShoppingmallShoppingcart({
          pageNum: 1,
          pageSize: 1000,
          type: this.valueType,
          brandName: this.valueBrand,
        }).then((res) => {
          this.cartList = [];
          this.cartList = res.data;

          this.cartList.forEach((item) => {
            item.checked = false;
            if (item.sellAttributeList) {
              let haveDictnameLP = item.sellAttributeList.filter((itemp) => {
                return itemp.dictname == "零批";
              });
              if (haveDictnameLP.length != 0) {
                item.lpButtonShow = true;
              } else {
                if (item.packingQuantity > item.availableQuantity) {
                  item.lpButtonShow = true;
                } else {
                  item.lpButtonShow = false;
                }
              }
              console.log(item.cinvCode + "-" + item.lpButtonShow);
            }

            item.countSum = 0;
            item.colorQuantity = 0;
            if (item.boxNumber == 0) {
              item.shoppingCartDeatailList.forEach((iteml) => {
                item.countSum = item.countSum * 1 + iteml.colorQuantity * 1;
              });
            } else {
              if (item.availableQuantity == item.quantity) {
                item.countSum = item.availableQuantity;
              } else {
                item.countSum = item.boxNumber * item.packingQuantity;
              }
            }
            // item.packingRatioList.forEach((items) => {
            //   item.colorQuantity = items.fpeiBi * item.boxNumber + item.colorQuantity * 1;
            // });
            // item.countSum = item.colorQuantity * 1 + item.countSum * 1;

            if (item.commodityPriceList && item.commodityPriceList.length != 0) {
              item.picePrice =
                item.commodityPriceList[
                  this.findMaxIndex(item.commodityPriceList, item.countSum)
                ].priceAfter;
            } else {
              item.picePrice = 0;
            }

            item.totalPrice = item.picePrice * item.countSum;
            item.volume =
              ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
            item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
            console.log(item.imageUrl);
            item.shoppingCartDeatailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              // console.log(items.imageUrls);
            });
            item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
          });
          console.log(this.cartList);
        });
      }
    },
    changeItemp(event) {
      if (this.valueBrand == "全部") {
        getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 1000 }).then((res) => {
          this.cartList = res.data;

          this.cartList.forEach((item) => {
            item.checked = false;
            if (item.sellAttributeList) {
              let haveDictnameLP = item.sellAttributeList.filter((itemp) => {
                return itemp.dictname == "零批";
              });
              if (haveDictnameLP.length != 0) {
                item.lpButtonShow = true;
              } else {
                if (item.packingQuantity > item.availableQuantity) {
                  item.lpButtonShow = true;
                } else {
                  item.lpButtonShow = false;
                }
              }
              console.log(item.cinvCode + "-" + item.lpButtonShow);
            }

            item.countSum = 0;
            item.colorQuantity = 0;
            if (item.boxNumber == 0) {
              item.shoppingCartDeatailList.forEach((iteml) => {
                item.countSum = item.countSum * 1 + iteml.colorQuantity * 1;
              });
            } else {
              if (item.availableQuantity == item.quantity) {
                item.countSum = item.availableQuantity;
              } else {
                item.countSum = item.boxNumber * item.packingQuantity;
              }
            }
            // item.packingRatioList.forEach((items) => {
            //   item.colorQuantity = items.fpeiBi * item.boxNumber + item.colorQuantity * 1;
            // });
            // item.countSum = item.colorQuantity * 1 + item.countSum * 1;

            if (item.commodityPriceList && item.commodityPriceList.length != 0) {
              item.picePrice =
                item.commodityPriceList[
                  this.findMaxIndex(item.commodityPriceList, item.countSum)
                ].priceAfter;
            } else {
              item.picePrice = 0;
            }

            item.totalPrice = item.picePrice * item.countSum;
            item.volume =
              ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
            item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
            console.log(item.imageUrl);
            item.shoppingCartDeatailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              // console.log(items.imageUrls);
            });
            item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
          });
          console.log(this.cartList);
        });
        this.$forceUpdate();
      } else {
        this.valueBrand = event;
        console.log(event);
        getDcShoppingmallShoppingcart({
          pageNum: 1,
          pageSize: 1000,
          type: this.valueType,
          brandName: this.valueBrand,
        }).then((res) => {
          this.cartList = res.data;

          this.cartList.forEach((item) => {
            item.checked = false;
            if (item.sellAttributeList) {
              let haveDictnameLP = item.sellAttributeList.filter((itemp) => {
                return itemp.dictname == "零批";
              });
              if (haveDictnameLP.length != 0) {
                item.lpButtonShow = true;
              } else {
                if (item.packingQuantity > item.availableQuantity) {
                  item.lpButtonShow = true;
                } else {
                  item.lpButtonShow = false;
                }
              }
              console.log(item.cinvCode + "-" + item.lpButtonShow);
            }

            item.countSum = 0;
            item.colorQuantity = 0;
            if (item.boxNumber == 0) {
              item.shoppingCartDeatailList.forEach((iteml) => {
                item.countSum = item.countSum * 1 + iteml.colorQuantity * 1;
              });
            } else {
              if (item.availableQuantity == item.quantity) {
                item.countSum = item.availableQuantity;
              } else {
                item.countSum = item.boxNumber * item.packingQuantity;
              }
            }
            // item.packingRatioList.forEach((items) => {
            //   item.colorQuantity = items.fpeiBi * item.boxNumber + item.colorQuantity * 1;
            // });
            // item.countSum = item.colorQuantity * 1 + item.countSum * 1;

            if (item.commodityPriceList && item.commodityPriceList.length != 0) {
              item.picePrice =
                item.commodityPriceList[
                  this.findMaxIndex(item.commodityPriceList, item.countSum)
                ].priceAfter;
            } else {
              item.picePrice = 0;
            }

            item.totalPrice = item.picePrice * item.countSum;
            item.volume =
              ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
            item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
            console.log(item.imageUrl);
            item.shoppingCartDeatailList.forEach((items) => {
              items.imageUrls =
                this.$locationUrl +
                "file/abyFile/commodityFile/commodityColorImage/" +
                items.fileName;
              items.imageUrlLists = [item.imageUrls];
              // console.log(items.imageUrls);
            });
            item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(0, 4);
          });
          console.log(this.cartList);
        });
        this.$forceUpdate();
      }
    },
    findMaxIndex(arr, num) {
      let maxIndex = -1;

      for (let i = 0; i < arr.length; i++) {
        if (num >= arr[i].startNum) {
          if (maxIndex === -1 || arr[i].startNum > arr[maxIndex].startNum) {
            maxIndex = i;
          }
        }
      }
      if (maxIndex == -1) {
        maxIndex = 0;
      }
      console.log("maxIndex", maxIndex);
      return maxIndex;
    },
    addDcOrdercenterOrderClick() {
      let cartLists = this.cartList.filter((item) => {
        return item.checked == true;
      });
      this.checkDifferentValueWithForEach(cartLists);
      if (this.foundDifferent == false) {
        let orderCommodityList = [];
        cartLists.forEach((item) => {
          orderCommodityList.push({ shoppingCartId: item.shoppingCartId });
          if (item.availableQuantity == item.countSum) {
            if (item.availableQuantity % item.packingQuantity != 0) {
              item.boxNumber = 0;
            }
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          }
        });
        if (this.checked1 != true) {
          this.totalTaxMoney = 0;
          this.tax = 0;
          this.taxRate = 0;
          this.isNeedInvoice = 0;
        } else {
          this.isNeedInvoice = 1;
        }
        addDcOrdercenterOrder({
          totalVolume: this.buyCounterVolumeSum.toFixed(2),
          totalBoxNumber: this.buyCounterCtnSum,
          totalMoney: this.buyCounterMoneySum,
          orderCommodityList: orderCommodityList,
          totalTaxMoney: this.totalTaxMoney,
          tax: this.tax,
          taxRate: this.taxRate,
          isNeedInvoice: this.isNeedInvoice,
          organizationId: 1,
        }).then((res) => {
          if (res.code == 200) {
            this.dialogVisible = true;
            this.orderId = res.data.orderId;
            this.foundDifferent = false;
          } else {
            // this.$notify({
            //   title: this.lange == "中文" ? "错误操作" : "Error",
            //   message:res.msg,
            //   type: "error",
            //   duration: 0,
            //   offset: 100,
            // });
          }
        });
      } else {
        this.foundDifferent = false;
      }
      console.log(this.foundDifferent);
    },
    selectItem(event, index) {
      this.showNumber = 0;
      if (event == true) {
        this.$set(this.cartList[index], "checked", true);
      } else {
        this.$set(this.cartList[index], "checked", false);
      }

      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.showNumber = this.showNumber * 1 + 1 * 1;
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      if (this.isNeedInvoice == 1) {
        this.taxRate = this.piaohaoObject.taxRate;
        this.totalTaxMoney = (
          this.buyCounterMoneySum *
          ((100 + this.taxRate) / 100)
        ).toFixed(2);
        this.tax = (this.buyCounterMoneySum * (this.taxRate / 100)).toFixed(2);
      } else {
        this.taxRate = 0;
        this.tax = 0;
        this.totalTaxMoney = this.buyCounterMoneySum;
      }
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemCB(event, index) {
      if (event == true) {
        this.$set(this.chrisbellaAll[index], "checked", true);
      } else {
        this.$set(this.chrisbellaAll[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemSUSEN(event, index) {
      if (event == true) {
        this.$set(this.SUSENAll[index], "checked", true);
      } else {
        this.$set(this.SUSENAll[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemBG(event, index) {
      if (event == true) {
        this.$set(this.BAGCOAll[index], "checked", true);
      } else {
        this.$set(this.BAGCOAll[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    yushouselectItem(event, index) {
      if (event == true) {
        this.$set(this.yushouCartList[index], "checked", true);
      } else {
        this.$set(this.yushouCartList[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    xianhuoselectItem(event, index) {
      if (event == true) {
        this.$set(this.xianhuoCartList[index], "checked", true);
      } else {
        this.$set(this.xianhuoCartList[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    allSelect(event) {
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;
      this.showNumber = 0;
      if (event == true) {
        this.cartList.forEach((item) => {
          item.checked = true;
          this.showNumber = this.showNumber * 1 + 1 * 1;
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        });
      } else {
        this.cartList.forEach((item) => {
          item.checked = false;
        });
        this.showNumber = 0;
        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;
      }
      if (this.isNeedInvoice == 1) {
        this.taxRate = this.piaohaoObject.taxRate;
        this.totalTaxMoney = (
          this.buyCounterMoneySum *
          ((100 + this.taxRate) / 100)
        ).toFixed(2);
        this.tax = (this.buyCounterMoneySum * (this.taxRate / 100)).toFixed(2);
      } else {
        this.taxRate = 0;
        this.tax = 0;
        this.totalTaxMoney = this.buyCounterMoneySum;
      }
    },
    selectItemyuAll(event, index) {
      if (event == true) {
        this.$set(this.yushouCartList[index], "checked", true);
      } else {
        this.$set(this.yushouCartList[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemyuAllCB(event, index) {
      if (event == true) {
        this.$set(this.chrisbellaAllys[index], "checked", true);
      } else {
        this.$set(this.chrisbellaAllys[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemyuAllSUSEN(event, index) {
      if (event == true) {
        this.$set(this.SUSENAllys[index], "checked", true);
      } else {
        this.$set(this.SUSENAllys[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemyuAllBG(event, index) {
      if (event == true) {
        this.$set(this.BAGCOAllys[index], "checked", true);
      } else {
        this.$set(this.BAGCOAllys[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemxhAll(event, index) {
      if (event == true) {
        this.$set(this.xianhuoCartList[index], "checked", true);
      } else {
        this.$set(this.xianhuoCartList[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemxhAllCB(event, index) {
      if (event == true) {
        this.$set(this.chrisbellaAllxh[index], "checked", true);
      } else {
        this.$set(this.chrisbellaAllxh[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemxhAllSUSEN(event, index) {
      if (event == true) {
        this.$set(this.SUSENAllxh[index], "checked", true);
      } else {
        this.$set(this.SUSENAllxh[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    selectItemxhAllBG(event, index) {
      if (event == true) {
        this.$set(this.BAGCOAllxh[index], "checked", true);
      } else {
        this.$set(this.BAGCOAllxh[index], "checked", false);
      }
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      console.log;
      this.$forceUpdate(this.buyCounterVolumeSum);
    },
    // =====================================================修改箱数==============================================================
    decrementPacking(item, index) {
      if (item.boxNumber == 1 || item.boxNumber == 0) {
        item.boxNumber = 1;
      } else {
        item.boxNumber--;
      }

      console.log(item);
      if (item.availableQuantity % item.packingQuantity == 0) {
        item = item.availableQuantity / item.packingQuantity;
      } else {
        item.buyCountMax =
          Math.floor(item.availableQuantity / item.packingQuantity) * 1 + 1;
      }
      console.log("最大后购买箱数", item.buyCountMax);
      item.countSum = 0;
      item.buyCountSumMoney = 0;
      console.log(item.boxMaxBumber, item.buyCountMax);

      console.log(item.boxNumber);
      //item.boxMaxBumber最大可成配比箱数
      //item.buyCountMax 最大可购买箱数
      if (item.boxNumber < item.buyCountMax) {
        if (item.boxNumber > item.boxMaxBumber) {
          item.countSum = 0;
          let fpeiBiPeiBiNumberCount = 0;

          let gapBox = item.boxNumber - item.boxMaxBumber; //输入值与最大装箱配比之间的差值 1
          item.packingRatioList.forEach((itemk) => {
            itemk.fpeiBiPeiBiNumber = 0;

            itemk.fpeiBiCopy = itemk.fpeiBi * 1 * item.boxMaxBumber * 1;

            itemk.fpeiBiPeiBiNumber = itemk.iquantity - itemk.fpeiBiCopy; //计算不成配比箱数各个颜色的剩余库存
            // // console.log(itemk)
            // console.log(itemk.fpeiBiPeiBiNumber);
            // console.log(itemk.fpeiBiCopy);
            console.log("1", itemk.fpeiBiCopy);
            console.log("2", itemk.fpeiBiPeiBiNumber);

            fpeiBiPeiBiNumberCount =
              itemk.fpeiBiPeiBiNumber * 1 + fpeiBiPeiBiNumberCount * 1; //计算不成配比箱数各个颜色的剩余库存之和
          });

          console.log("fpeiBiPeiBiNumberCount", fpeiBiPeiBiNumberCount);
          if (fpeiBiPeiBiNumberCount > item.packingQuantity) {
            //不成配比箱数各个颜色的剩余库存之和大于配比数时
            console.log("//不成配比箱数各个颜色的剩余库存之和大于配比数时");
            let fpeiBiCopySumNumber = 0;
            item.packingRatioList.forEach((itemp) => {
              itemp.fpeiBiPeiBiNumberLv = (
                itemp.fpeiBiPeiBiNumber / fpeiBiPeiBiNumberCount
              ).toFixed(2); //计算不成配比箱数各个颜色的新配比比率
              fpeiBiCopySumNumber =
                Math.round(item.packingQuantity * itemp.fpeiBiPeiBiNumberLv * gapBox) +
                fpeiBiCopySumNumber; //按照不成配比箱数各个颜色的新配比比率重新配比装箱

              itemp.fpeiBiCopy =
                itemp.fpeiBiCopy +
                Math.round(item.packingQuantity * itemp.fpeiBiPeiBiNumberLv * gapBox);
            });
            console.log(
              "//按照不成配比箱数各个颜色的新配比比率重新配比装箱",
              fpeiBiCopySumNumber
            );
            if (fpeiBiCopySumNumber < item.packingQuantity * gapBox) {
              //重新配比装箱数小于系统配比装箱数
              console.log("配比计算数小于配比数");
              const maxElement = item.packingRatioList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < item.packingRatioList.length; i++) {
                if (
                  item.packingRatioList[i].fpeiBiPeiBiNumber ===
                  maxElement.fpeiBiPeiBiNumber
                ) {
                  item.packingRatioList[i].fpeiBiCopy =
                    item.packingRatioList[i].fpeiBiCopy * 1 + 1 * gapBox; // 只给第一个符合条件的元素加1

                  break; // 找到后退出循环
                }
              }
            } else if (fpeiBiCopySumNumber > item.packingQuantity * gapBox) {
              const maxElement = item.packingRatioList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < item.packingRatioList.length; i++) {
                if (
                  item.packingRatioList[i].fpeiBiPeiBiNumber ===
                  maxElement.fpeiBiPeiBiNumber
                ) {
                  item.packingRatioList[i].fpeiBiCopy =
                    item.packingRatioList[i].fpeiBiCopy * 1 - 1 * gapBox; // 只给第一个符合条件的元素加1
                  console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                  break; // 找到后退出循环
                }
              }
            }

            // this.buyCount = this.buyCount + 1;

            item.countSum = item.boxNumber * item.packingQuantity;
            console.log("00000000", item.countSum);
          } else {
            console.log(
              "数量剩余数小于配比装箱数--------------------------------------------------------"
            );

            item.boxNumber = item.buyCountMax;
            item.packingRatioList.forEach((item) => {
              item.fpeiBiCopy = item.iquantity;
              item.buyCountSum = item.availableQuantity;
            });
          }
        } else if (item.availableQuantity == 0) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "库存不足无法修改"
                : "Insufficient inventory cannot be modified",
            type: "error",
            duration: 0,
            offset: 100,
          });
        } else {
          item.packingRatioList.forEach((itemj) => {
            itemj.fpeiBiCopy = itemj.fpeiBi * item.boxNumber;
            item.countSum = item.countSum * 1 + itemj.fpeiBiCopy * 1;
          });
          console.log();
        }
      } else {
        item.boxNumber = item.buyCountMax;
        item.countSum = item.availableQuantity;
        item.packingRatioList.forEach((itemt) => {
          itemt.fpeiBiCopy = itemt.iquantity;
        });
      }
      item.price =
        item.commodityPriceList[
          this.findMaxIndex(item.commodityPriceList, item.countSum)
        ].priceAfter;
      item.totalPrice = item.countSum * item.price;

      item.volume = ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
      item.packingRatioList.forEach((itemPackingRatioList) => {
        item.shoppingCartDeatailList.forEach((itemShoppingCartDeatailList) => {
          if (itemPackingRatioList.id == itemShoppingCartDeatailList.peibiId) {
            itemShoppingCartDeatailList.colorQuantity = itemPackingRatioList.fpeiBiCopy;
          }
        });
      });
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      let shoppingCartDeatailList = [];
      this.cartList[index].shoppingCartDeatailList.forEach((item) => {
        shoppingCartDeatailList.push({
          colorName: item.colorName,
          peibiId: item.peibiId,
          colorQuantity: item.colorQuantity,
          shoppingCartId: item.shoppingCartId,
        });
      });
      editDcShoppingmallShoppingcart({
        shoppingCartId: this.cartList[index].shoppingCartId,
        picePrice: this.cartList[index].picePrice,
        totalPrice: this.cartList[index].totalPrice,
        quantity: this.cartList[index].countSum,
        boxNumber: this.cartList[index].boxNumber,
        volume: this.cartList[index].volume,
        sellAttributeId: this.cartList[index].sellAttributeId,
        cinvCode: this.cartList[index].cinvCode,
        shoppingCartDeatailList: shoppingCartDeatailList,
      }).then((res) => {
        if (res.code == 200) {
        }
      });
      this.$forceUpdate();
    },
    incrementPacking(item, index) {
      item.boxNumber = item.boxNumber * 1 + 1;
      console.log(item);
      if (item.availableQuantity % item.packingQuantity == 0) {
        item.buyCountMax = item.availableQuantity / item.packingQuantity;
      } else {
        item.buyCountMax =
          Math.floor(item.availableQuantity / item.packingQuantity) * 1 + 1;
      }
      console.log("最大后购买箱数", item.buyCountMax);
      item.countSum = 0;
      item.buyCountSumMoney = 0;
      console.log(item.boxMaxBumber, item.buyCountMax);

      console.log(item.boxNumber);
      //item.boxMaxBumber最大可成配比箱数
      //item.buyCountMax 最大可购买箱数
      if (item.boxNumber < item.buyCountMax) {
        if (item.boxNumber > item.boxMaxBumber) {
          item.countSum = 0;
          let fpeiBiPeiBiNumberCount = 0;

          let gapBox = item.boxNumber - item.boxMaxBumber; //输入值与最大装箱配比之间的差值 1
          item.packingRatioList.forEach((itemk) => {
            itemk.fpeiBiPeiBiNumber = 0;

            itemk.fpeiBiCopy = itemk.fpeiBi * 1 * item.boxMaxBumber * 1;

            itemk.fpeiBiPeiBiNumber = itemk.iquantity - itemk.fpeiBiCopy; //计算不成配比箱数各个颜色的剩余库存
            // // console.log(itemk)
            // console.log(itemk.fpeiBiPeiBiNumber);
            // console.log(itemk.fpeiBiCopy);
            console.log("1", itemk.fpeiBiCopy);
            console.log("2", itemk.fpeiBiPeiBiNumber);

            fpeiBiPeiBiNumberCount =
              itemk.fpeiBiPeiBiNumber * 1 + fpeiBiPeiBiNumberCount * 1; //计算不成配比箱数各个颜色的剩余库存之和
          });

          console.log("fpeiBiPeiBiNumberCount", fpeiBiPeiBiNumberCount);
          if (fpeiBiPeiBiNumberCount > item.packingQuantity) {
            //不成配比箱数各个颜色的剩余库存之和大于配比数时
            console.log("//不成配比箱数各个颜色的剩余库存之和大于配比数时");
            let fpeiBiCopySumNumber = 0;
            item.packingRatioList.forEach((itemp) => {
              itemp.fpeiBiPeiBiNumberLv = (
                itemp.fpeiBiPeiBiNumber / fpeiBiPeiBiNumberCount
              ).toFixed(2); //计算不成配比箱数各个颜色的新配比比率
              fpeiBiCopySumNumber =
                Math.round(item.packingQuantity * itemp.fpeiBiPeiBiNumberLv * gapBox) +
                fpeiBiCopySumNumber; //按照不成配比箱数各个颜色的新配比比率重新配比装箱

              itemp.fpeiBiCopy =
                itemp.fpeiBiCopy +
                Math.round(item.packingQuantity * itemp.fpeiBiPeiBiNumberLv * gapBox);
            });
            console.log(
              "//按照不成配比箱数各个颜色的新配比比率重新配比装箱",
              fpeiBiCopySumNumber
            );
            if (fpeiBiCopySumNumber < item.packingQuantity * gapBox) {
              //重新配比装箱数小于系统配比装箱数
              console.log("配比计算数小于配比数");
              const maxElement = item.packingRatioList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < item.packingRatioList.length; i++) {
                if (
                  item.packingRatioList[i].fpeiBiPeiBiNumber ===
                  maxElement.fpeiBiPeiBiNumber
                ) {
                  item.packingRatioList[i].fpeiBiCopy =
                    item.packingRatioList[i].fpeiBiCopy * 1 + 1 * gapBox; // 只给第一个符合条件的元素加1

                  break; // 找到后退出循环
                }
              }
            } else if (fpeiBiCopySumNumber > item.packingQuantity * gapBox) {
              const maxElement = item.packingRatioList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < item.packingRatioList.length; i++) {
                if (
                  item.packingRatioList[i].fpeiBiPeiBiNumber ===
                  maxElement.fpeiBiPeiBiNumber
                ) {
                  item.packingRatioList[i].fpeiBiCopy =
                    item.packingRatioList[i].fpeiBiCopy * 1 - 1 * gapBox; // 只给第一个符合条件的元素加1
                  console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                  break; // 找到后退出循环
                }
              }
            }

            // this.buyCount = this.buyCount + 1;

            item.countSum = item.boxNumber * item.packingQuantity;
            console.log("00000000", item.countSum);
          } else {
            console.log(
              "数量剩余数小于配比装箱数--------------------------------------------------------"
            );

            item.boxNumber = item.buyCountMax;
            item.packingRatioList.forEach((item) => {
              item.fpeiBiCopy = item.iquantity;
              item.buyCountSum = item.availableQuantity;
            });
          }
        } else if (item.availableQuantity == 0) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "库存不足无法修改"
                : "Insufficient inventory cannot be modified",
            type: "error",
            duration: 0,
            offset: 100,
          });
        } else {
          item.packingRatioList.forEach((itemj) => {
            itemj.fpeiBiCopy = itemj.fpeiBi * item.boxNumber;
            item.countSum = item.countSum * 1 + itemj.fpeiBiCopy * 1;
          });
          console.log();
        }
      } else {
        item.boxNumber = item.buyCountMax;
        item.countSum = item.availableQuantity;
        item.packingRatioList.forEach((itemt) => {
          itemt.fpeiBiCopy = itemt.iquantity;
        });
      }
      item.price =
        item.commodityPriceList[
          this.findMaxIndex(item.commodityPriceList, item.countSum)
        ].priceAfter;
      item.totalPrice = item.countSum * item.price;

      item.volume = ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
      item.packingRatioList.forEach((itemPackingRatioList) => {
        item.shoppingCartDeatailList.forEach((itemShoppingCartDeatailList) => {
          if (itemPackingRatioList.id == itemShoppingCartDeatailList.peibiId) {
            itemShoppingCartDeatailList.colorQuantity = itemPackingRatioList.fpeiBiCopy;
          }
        });
      });
      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      let shoppingCartDeatailList = [];
      this.cartList[index].shoppingCartDeatailList.forEach((item) => {
        shoppingCartDeatailList.push({
          colorName: item.colorName,
          peibiId: item.peibiId,
          colorQuantity: item.colorQuantity,
          shoppingCartId: item.shoppingCartId,
        });
      });
      editDcShoppingmallShoppingcart({
        shoppingCartId: this.cartList[index].shoppingCartId,
        picePrice: this.cartList[index].picePrice,
        totalPrice: this.cartList[index].totalPrice,
        quantity: this.cartList[index].countSum,
        boxNumber: this.cartList[index].boxNumber,
        volume: this.cartList[index].volume,
        sellAttributeId: this.cartList[index].sellAttributeId,
        cinvCode: this.cartList[index].cinvCode,
        shoppingCartDeatailList: shoppingCartDeatailList,
      }).then((res) => {
        if (res.code == 200) {
        }
      });
      this.$forceUpdate();
    },
    // =====================================================零批数量减少==============================================================
    decrementItem(item, index, indexFather) {
      console.log(item);
      this.buyCountSum = 0;
      this.buyCountSumMoney = 0;
      this.$set(this.cartList[indexFather], "boxNumber", 0);
      if (item.colorQuantity <= 1) {
        // this.$set(
        //   this.cartList[indexFather].shoppingCartDeatailListCopy[index],
        //   "colorQuantity",
        //   1
        // );
        this.$set(
          this.cartList[indexFather].shoppingCartDeatailList[index],
          "colorQuantity",
          1
        );
        this.cartList[indexFather].countSum = 0;
        this.cartList[indexFather].shoppingCartDeatailList.forEach((itemp) => {
          console.log(itemp.colorQuantity);
          this.cartList[indexFather].countSum =
            itemp.colorQuantity * 1 + this.cartList[indexFather].countSum * 1;
        });
        this.cartList[indexFather].volume =
          (this.cartList[indexFather].ivolume /
            this.cartList[indexFather].packingQuantity /
            1000000) *
          this.cartList[indexFather].countSum;

        this.cartList[indexFather].picePrice = this.cartList[
          indexFather
        ].commodityPriceList[
          this.findMaxIndex(
            this.cartList[indexFather].commodityPriceList,
            this.cartList[indexFather].countSum
          )
        ].priceAfter;

        this.cartList[indexFather].totalPrice =
          this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;

        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;

        this.cartList.forEach((item) => {
          if (item.checked == true) {
            this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
            this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
            this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
          }
        });
        this.$forceUpdate();
        return;
      }
      // this.$set(
      //   this.cartList[indexFather].shoppingCartDeatailListCopy[index],
      //   "colorQuantity",
      //   item.colorQuantity - 1
      // );
      this.$set(
        this.cartList[indexFather].shoppingCartDeatailList[index],
        "colorQuantity",
        item.colorQuantity - 1
      );
      this.cartList[indexFather].countSum = 0;
      this.cartList[indexFather].shoppingCartDeatailList.forEach((itemp) => {
        console.log(itemp.colorQuantity);
        this.cartList[indexFather].countSum =
          itemp.colorQuantity * 1 + this.cartList[indexFather].countSum * 1;
      });
      this.cartList[indexFather].volume =
        (this.cartList[indexFather].ivolume /
          this.cartList[indexFather].packingQuantity /
          1000000) *
        this.cartList[indexFather].countSum;
      this.cartList[indexFather].picePrice = this.cartList[
        indexFather
      ].commodityPriceList[
        this.findMaxIndex(
          this.cartList[indexFather].commodityPriceList,
          this.cartList[indexFather].countSum
        )
      ].priceAfter;

      this.cartList[indexFather].totalPrice =
        this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;

      this.buyCounterPcsSum = 0;
      this.buyCounterVolumeSum = 0;
      this.buyCounterCtnSum = 0;
      this.buyCounterMoneySum = 0;

      this.cartList.forEach((item) => {
        if (item.checked == true) {
          this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
          this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
          this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
          this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
        }
      });
      let shoppingCartDeatailList = [];
      this.cartList[indexFather].shoppingCartDeatailList.forEach((item) => {
        shoppingCartDeatailList.push({
          colorName: item.colorName,
          peibiId: item.peibiId,
          colorQuantity: item.colorQuantity,
          shoppingCartId: item.shoppingCartId,
        });
      });
      editDcShoppingmallShoppingcart({
        shoppingCartId: this.cartList[indexFather].shoppingCartId,
        picePrice: this.cartList[indexFather].picePrice,
        totalPrice: this.cartList[indexFather].totalPrice,
        volume: this.cartList[indexFather].volume.toFixed(2),
        quantity: this.cartList[indexFather].countSum,
        boxNumber: 0,
        sellAttributeId: this.cartList[indexFather].sellAttributeId,
        cinvCode: this.cartList[indexFather].cinvCode,
        shoppingCartDeatailList: shoppingCartDeatailList,
      }).then((res) => {
        if (res.code == 200) {
        }
      });
      this.$forceUpdate();
    },
    // =============================================================================================================================

    // =====================================================零批数量增加==============================================================
    incrementItem(item, index, indexFather) {
      this.$set(
        this.cartList[indexFather].shoppingCartDeatailList[index],
        "colorQuantity",
        item.colorQuantity * 1 + 1
      );
      this.$set(this.cartList[indexFather], "boxNumber", 0);
      this.cartList[indexFather].countSum = 0;
      this.cartList[indexFather].shoppingCartDeatailList.forEach((itemp) => {
        console.log(itemp.colorQuantity);
        this.cartList[indexFather].countSum =
          itemp.colorQuantity * 1 + this.cartList[indexFather].countSum * 1;
      });
      console.log(this.cartList[indexFather].countSum);

      if (
        this.cartList[indexFather].countSum > this.cartList[indexFather].availableQuantity
      ) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "购买数量不能大于库存数"
              : "Stock is not available, please reduce your order quantity",
          type: "error",
          duration: 0,
          offset: 100,
        });
        this.$set(
          this.cartList[indexFather].shoppingCartDeatailList[index],
          "colorQuantity",
          item.colorQuantity * 1 - 1
        );
        this.$set(this.cartList[indexFather], "boxNumber", 0);
        this.cartList[indexFather].countSum = 0;
        this.cartList[indexFather].shoppingCartDeatailList.forEach((itemp) => {
          console.log(itemp.colorQuantity);
          this.cartList[indexFather].countSum =
            itemp.colorQuantity * 1 + this.cartList[indexFather].countSum * 1;
        });
        this.cartList[indexFather].volume =
          (this.cartList[indexFather].ivolume /
            this.cartList[indexFather].packingQuantity /
            1000000) *
          this.cartList[indexFather].countSum;
        this.cartList[indexFather].picePrice = this.cartList[
          indexFather
        ].commodityPriceList[
          this.findMaxIndex(
            this.cartList[indexFather].commodityPriceList,
            this.cartList[indexFather].countSum
          )
        ].priceAfter;

        this.cartList[indexFather].totalPrice =
          this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;

        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;

        this.cartList.forEach((item) => {
          if (item.checked == true) {
            this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
            this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
            this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
          }
        });
        let shoppingCartDeatailList = [];
        this.cartList[indexFather].shoppingCartDeatailList.forEach((item) => {
          shoppingCartDeatailList.push({
            colorName: item.colorName,
            peibiId: item.peibiId,
            colorQuantity: item.colorQuantity,
            shoppingCartId: item.shoppingCartId,
          });
        });
        editDcShoppingmallShoppingcart({
          shoppingCartId: this.cartList[indexFather].shoppingCartId,
          picePrice: this.cartList[indexFather].picePrice,
          totalPrice: this.cartList[indexFather].totalPrice,
          volume: this.cartList[indexFather].volume.toFixed(2),
          boxNumber: 0,
          quantity: this.cartList[indexFather].countSum,
          sellAttributeId: this.cartList[indexFather].sellAttributeId,
          cinvCode: this.cartList[indexFather].cinvCode,
          shoppingCartDeatailList: shoppingCartDeatailList,
        }).then((res) => {
          if (res.code == 200) {
          }
        });
        this.$forceUpdate();
      } else {
        this.cartList[indexFather].volume =
          (this.cartList[indexFather].ivolume /
            this.cartList[indexFather].packingQuantity /
            1000000) *
          this.cartList[indexFather].countSum;
        this.cartList[indexFather].picePrice = this.cartList[
          indexFather
        ].commodityPriceList[
          this.findMaxIndex(
            this.cartList[indexFather].commodityPriceList,
            this.cartList[indexFather].countSum
          )
        ].priceAfter;

        this.cartList[indexFather].totalPrice =
          this.cartList[indexFather].picePrice * this.cartList[indexFather].countSum;

        this.buyCounterPcsSum = 0;
        this.buyCounterVolumeSum = 0;
        this.buyCounterCtnSum = 0;
        this.buyCounterMoneySum = 0;

        this.cartList.forEach((item) => {
          if (item.checked == true) {
            this.buyCounterPcsSum = this.buyCounterPcsSum * 1 + item.countSum;
            this.buyCounterVolumeSum = this.buyCounterVolumeSum * 1 + item.volume;
            this.buyCounterCtnSum = this.buyCounterCtnSum * 1 + item.boxNumber;
            this.buyCounterMoneySum = this.buyCounterMoneySum * 1 + item.totalPrice;
          }
        });
        let shoppingCartDeatailList = [];
        this.cartList[indexFather].shoppingCartDeatailList.forEach((item) => {
          shoppingCartDeatailList.push({
            colorName: item.colorName,
            peibiId: item.peibiId,
            colorQuantity: item.colorQuantity,
            shoppingCartId: item.shoppingCartId,
          });
        });
        editDcShoppingmallShoppingcart({
          shoppingCartId: this.cartList[indexFather].shoppingCartId,
          picePrice: this.cartList[indexFather].picePrice,
          totalPrice: this.cartList[indexFather].totalPrice,
          volume: this.cartList[indexFather].volume.toFixed(2),
          boxNumber: 0,
          quantity: this.cartList[indexFather].countSum,
          sellAttributeId: this.cartList[indexFather].sellAttributeId,
          cinvCode: this.cartList[indexFather].cinvCode,
          shoppingCartDeatailList: shoppingCartDeatailList,
        }).then((res) => {
          if (res.code == 200) {
          }
        });
        this.$forceUpdate();
      }
    },
    // =============================================================================================================================
    muchShowClick(index) {
      if (this.muchShow == true) {
        this.cartList[index].shoppingCartDeatailListCopy = this.cartList[
          index
        ].shoppingCartDeatailList;
      } else {
        this.cartList[index].shoppingCartDeatailListCopy = this.cartList[
          index
        ].shoppingCartDeatailList.slice(0, 4);
      }
      this.muchShow = !this.muchShow;
    },
    goToChangeItem(item) {
      // window.open(
      //   this.$locationViewUrl+"#/goods/Detail?cinvCode=" + item.cinvCode,
      //   "_blank"
      // );
      window.open(
        this.$locationViewUrl +
          "#/goods/cartItemDetail?cinvCode=" +
          item.cinvCode +
          "&brandName=" +
          this.brandName +
          "&shoppingCartId=" +
          item.shoppingCartId,
        "_blank"
      );
    },
    deleteCartItem(item) {
      console.log(item.shoppingCartId);
      this.$confirm("此操作将该商品从购物车中删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteDcShoppingmallShoppingcart(item.shoppingCartId).then((res) => {
            if (res.code == 200) {
              this.cartList = [];

              getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 10000 }).then(
                (res) => {
                  this.cartList = res.data;

                  this.cartList.forEach((item) => {
                    item.checked = false;
                    if (item.sellAttributeList) {
                      let haveDictnameLP = item.sellAttributeList.filter((itemp) => {
                        return itemp.dictname == "零批";
                      });
                      if (haveDictnameLP.length != 0) {
                        item.lpButtonShow = true;
                      } else {
                        if (item.packingQuantity > item.availableQuantity) {
                          item.lpButtonShow = true;
                        } else {
                          item.lpButtonShow = false;
                        }
                      }
                      console.log(item.cinvCode + "-" + item.lpButtonShow);
                    }

                    item.countSum = 0;
                    item.colorQuantity = 0;
                    if (item.boxNumber == 0) {
                      item.shoppingCartDeatailList.forEach((iteml) => {
                        item.countSum = item.countSum * 1 + iteml.colorQuantity * 1;
                      });
                    } else {
                      if (item.availableQuantity == item.quantity) {
                        item.countSum = item.availableQuantity;
                      } else {
                        item.countSum = item.boxNumber * item.packingQuantity;
                      }
                    }
                    // item.packingRatioList.forEach((items) => {
                    //   item.colorQuantity = items.fpeiBi * item.boxNumber + item.colorQuantity * 1;
                    // });
                    // item.countSum = item.colorQuantity * 1 + item.countSum * 1;

                    if (item.commodityPriceList && item.commodityPriceList.length != 0) {
                      item.picePrice =
                        item.commodityPriceList[
                          this.findMaxIndex(item.commodityPriceList, item.countSum)
                        ].priceAfter;
                    } else {
                      item.picePrice = 0;
                    }

                    item.totalPrice = item.picePrice * item.countSum;
                    item.volume =
                      ((item.countSum / item.packingQuantity) * item.ivolume) / 1000000;
                    item.imageUrl = this.$locationUrl + "file/" + item.imageUrl;
                    console.log(item.imageUrl);
                    item.shoppingCartDeatailList.forEach((items) => {
                      items.imageUrls =
                        this.$locationUrl +
                        "file/abyFile/commodityFile/commodityColorImage/" +
                        items.fileName;
                      items.imageUrlLists = [item.imageUrls];
                      // console.log(items.imageUrls);
                    });
                    item.shoppingCartDeatailListCopy = item.shoppingCartDeatailList.slice(
                      0,
                      4
                    );
                  });
                  console.log(this.cartList);
                }
              );
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addDcOrdercenterOrderClick1() {
      this.$router.push({
        path: "/goods/goodOrder",
        query: { brandName: this.brandName },
      });

      this.$notify({
        title: this.lange == "中文" ? "成功" : "succsee",
        message: this.lange == "中文" ? "订单已创建" : "Order created",
        type: "success",
        duration: 0,
        offset: 100,
      });
    },
    addDcOrdercenterOrderClick2() {
      this.$router.push({
        path: "/goods/goodOrder",
        query: { brandName: this.brandName },
      });
      this.$notify({
        title: this.lange == "中文" ? "成功" : "succsee",
        message: this.lange == "中文" ? "订单已创建" : "Order created",
        type: "success",
        duration: 0,
        offset: 100,
      });
    },
    buyGoods(item) {
      // this.$store.commit('getGoodsInfo', item)
      this.$router.push({
        path: "/goods/goodOrderDetail",
        query: { brandName: this.brandName },
      });
    },
    getImageHeight() {
      this.dynamicHeight = this.$refs.images.height + "px";
    },
    sumChange(a, b) {
      console.log("3213123");
      console.log(a, b);
      if (a == 1) {
        this.allShop = this.allShop * 1 + b * 1;
      } else {
        this.allShop = this.allShop * 1 - b * 1;
      }
    },

    deleteMultipe() {
      this.dialogTableVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}
.counter1 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 50%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 31px;
  border-radius: 40px;
}
.counters {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 11px;
  border-radius: 40px;
}

// .counters span {
//   position: absolute;
//   left: 50%;
//   font-size: 15px;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

// .counters::before,
// .counters::after {
//   content: "";
//   cursor: pointer;
// }

// .counters::before {
//   content: "-";
//   font-size: 12px;
//   margin-left: 4px;
// }

// .counters::after {
//   content: "+";
//   font-size: 12px;
//   margin-right: 4px;
// }
.counter {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 90%;
  padding: 5px;
  border: 2px solid #d7d7d7;
  position: relative;

  border-radius: 15px;
  /* height: 40%; */
  line-height: 11px;
}
.shopping {
  .content {
    background-color: #fff;

    margin-bottom: 45px;
    font-family: "Microsoft YaHei";
    padding-bottom: 10px;
  }
}

.hd,
.cart-table-th {
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
}

.hd {
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .car {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .cart-sum {
    strong {
      font-size: 22px;
      font-weight: 700;
      color: #f50;
      margin-right: 12px;
    }

    a {
      $h: 42px;
      display: inline-block;
      text-align: center;
      line-height: $h;
      @include wh(74px, $h);
      background: #aaa;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      cursor: not-allowed;
    }

    .active {
      background-color: #f50;
      cursor: pointer;
    }
  }
}

.cartmain {
  user-select: none;
  $h: 50px;

  .cart-table-th {
    padding: 0 25px;
    height: $h;
    margin-bottom: 15px;
  }

  .th {
    height: 100%;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    line-height: $h;
  }

  .default-list {
    color: #aaaaaa;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 300px;
  }
}
</style>
<style lang="scss">
.th {
  flex: 2;
}

.th-chk {
  flex: 1.2;

  // width: 150px;
  input,
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    margin-right: 8px;
  }

  label {
    width: 50px;
    height: 50px;
    display: inline-block;
  }
}

.th-info {
  flex: 2.8;
}

.th-price,
.th-amount,
.th-sum,
.th-op {
  text-align: center;
}
// body {
//   font-size: 16px; /* 默认字体大小 */
//   @media only screen and (max-width: 1200px) {
//     font-size: 14px !important; /* 中等屏幕 */
//   }
//   @media only screen and (max-width: 768px) {
//     font-size: 12px !important; /* 中等屏幕 */
//   }
//   @media only screen and (max-width: 480px) {
//     font-size: 10px !important; /* 中等屏幕 */
//   }
// }
// body {
//   font-size: 16px; /* 默认字体大小 */
// }

// /* 媒体查询 */
// @media (max-width: 1200px) {
//   body {
//     font-size: 14px; /* 中等屏幕 */
//   }
// }

// @media (max-width: 768px) {
//   body {
//     font-size: 12px; /* 小屏幕 */
//   }
// }

// @media (max-width: 480px) {
//   body {
//     font-size: 10px; /* 超小屏幕 */
//   }
// }
</style>
