<template>
  <div style="width: 500px;">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="原始密码" prop="oldPwd">
        <el-input
          v-model="form.oldPwd"
          show-password
          type="password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPwd">
        <el-input
          v-model="form.newPwd"
          show-password
          type="newPwd"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPwd">
        <el-input
          v-model="form.checkPwd"
          show-password
          type="checkPwd"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div>
      <el-button @click="reset">重 置</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { changePwd } from "@/api/other/index";
import { isHaveBlankValid, isUsernameValid } from "@/utils/validator";

export default {
  name: "ChangePwd",
  data() {
    return {
      form: {
        oldPwd: "",
        newPwd: "",
        checkPwd: "",
      },
      rules: {
        oldPwd: [{ required: true, trigger: "blur", message: "请输入原始密码" }],
        newPwd: [
          { required: true, trigger: "blur", message: "请输入新密码" },
          { validator: isHaveBlankValid, trigger: "blur" },
          { validator: isUsernameValid, trigger: "blur" },
        ],
        checkPwd: [
          { validator: isUsernameValid, trigger: "blur" },
          { validator: isHaveBlankValid, trigger: "blur" },
          { required: true, validator: this.validatePassword },
        ],
      },
    };
  },
  created() {},
  methods: {
    validatePassword(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    reset() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
    },
    submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          var formData = new FormData();
          formData.append("userId", localStorage.getItem("userId"));
          formData.append("oldPwd", this.form.oldPwd);
          formData.append("newPwd", this.form.newPwd);
          formData.append("checkPwd", this.form.checkPwd);
          const { success, msg } = await changePwd(formData);
          if (success) {
            this.reset();
            this.$notify({
              title: this.lange == "中文" ? "操作成功" : "Succsee",
              message:
                this.lange == "中文"
                  ? "密码已修改请重新登录"
                  : "Password has been changed. Please log in again",
              type: "success",
              duration: 0,
              offset: 100,
            });
            localStorage.clear();
            sessionStorage.clear();
            window.open(this.$locationViewUrl + "#/MallHomepage", "_blank");
          } else {
            this.$baseMessage(msg, "error");
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
