<template>
  <div class="personalCenter-container" style="margin-top: 100px;">
    <el-tabs :tab-position="tabPosition">
      <!-- <el-tab-pane label="个人简介">个人简介</el-tab-pane> -->
      <!-- <el-tab-pane label="基本设置">基本设置</el-tab-pane> -->
      <el-tab-pane label="安全设置">
        <h2>安全设置</h2>
        <el-divider></el-divider>
        <!-- 修改密码 -->
        <change-pwd></change-pwd>
      </el-tab-pane>
      <!-- <el-tab-pane label="账户绑定">安全设置</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
  import ChangePwd from './components/ChangePwd'

  export default {
    name: 'PersonalCenter',
    components: { ChangePwd },

    data() {
      return {
        tabPosition: 'left',
      }
    },
    created() {},
    methods: {},
  }
</script>
